import mixpanel from "mixpanel-browser";

const apiKey = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(apiKey as string, {
  debug: process.env.NODE_ENV === "development",
});

const { userAgent } = window.navigator;

export const events = {
  CREATED_ACCOUNT: "CREATED_ACCOUNT",

  // Jobs
  VISITED_JOB: "VISITED_JOB",
  VIEWED_JOBS: "VIEWED_JOBS",
  VIEWED_MORE_JOBS: "VIEWED_MORE_JOBS",

  // Referrals
  SUBMITTED_REFERRAL: "SUBMITTED_REFERRAL",
  APPLIED: "APPLIED",

  // Auth
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",

  // Experiments
  VIEWED_EXPERIMENT: "VIEWED_EXPERIMENT",
};

export type TrackingOptions = {
  email?: string;
  [key: string]: any;
};

export function normalizeTrackingOptions(
  options?: TrackingOptions
): { [key: string]: any } | null {
  if (!options) {
    return null;
  }

  const { email, ...rest } = options;

  return rest;
}

let shouldSendEvents =
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "development";

const IGNORED_USER_AGENTS = [
  "facebookexternalhit",
  "Facebot",
  "Twitterbot",
  "LinkedInBot",
  "WhatsApp",
  "Slackbot",
  "TelegramBot",
  "Googlebot",
  "Bingbot",
  "Baiduspider",
  "YandexBot",
  "Sogou",
  "Exabot",
  "ia_archiver",
  "HeadlessChrome",
];

if (IGNORED_USER_AGENTS.some((ua) => userAgent.includes(ua))) {
  shouldSendEvents = false;
}

const canUseMixpanel =
  apiKey !== undefined &&
  process.env.REACT_APP_IS_VENDOR_SITE !== "1" &&
  shouldSendEvents;

export async function identify(
  id: string | null,
  options?: TrackingOptions
): Promise<void> {
  const properties = normalizeTrackingOptions(options);

  if (!canUseMixpanel) return;

  if (id) {
    mixpanel.identify(id);

    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Analytics: Identified user", id);
    }

    if (properties) {
      mixpanel.people.set({
        ...properties,
        userAgent,
      });
    }
  } else {
    mixpanel.reset();
  }
}

export async function track(
  event: string,
  options?: TrackingOptions
): Promise<void> {
  const properties = normalizeTrackingOptions(options);

  if (!canUseMixpanel) return;

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("Analytics:", event, properties);
  }

  if (properties) {
    mixpanel.track(event, {
      ...properties,
      userAgent,
    });
  } else {
    mixpanel.track(event, {
      userAgent,
    });
  }
}

export async function setProperties(properties: object) {
  if (!canUseMixpanel) return;

  mixpanel.people.set({
    ...properties,
    userAgent,
  });

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("Analytics: Set Properties", properties);
  }
}

export interface GetUidParams {
  email: string;
}

export const getUid = ({ email = "" }: GetUidParams): string => {
  return email.toLowerCase();
};

export default {
  events,
  identify,
  track,
};

/* eslint-disable no-lonely-if */
import React, { useCallback, useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.min.css";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import {
  Button,
  FileUpload,
  FormSubmissionPolicy,
  Input,
  Label,
  Text,
} from "components";
import { FileData, jobsActions } from "reducks/jobs";
import { REFERRALS_PATH, SIGN_UP_PATH, getLoginPath } from "routes";
import {
  selectIsUserLoggedIn,
  selectSentByUser,
  selectUser,
} from "reducks/user";
import HmmImage from "images/hmm.png";
import { RootState } from "reducks/rootReducer";
import { ReactComponent as SuccessCheckIcon } from "images/success-check.svg";
import { selectAppSettings } from "reducks/meta";
import { selectLoader } from "reducks/loaders";
import { validateEmail } from "utilities/forms";

import { events, track } from "utilities/analytics";
import css from "./ReferCandidateForm.module.scss";

interface ReferCandidateFormProps {
  jobId: number;
  reward: string;
}

const ReferralSuccessToast = () => (
  <div className={css.ReferralSuccessToast} data-cy="success-toast">
    <div className={css.ReferralSuccessContent}>
      <SuccessCheckIcon className={css.ReferralSuccessIcon} />
      <div className={css.ReferralSuccessContentText}>
        <p className={css.ReferralSuccessToastHeader}>
          Your referral has been submitted!
        </p>
        <Link className={css.ReferralSuccessToastLink} to={REFERRALS_PATH}>
          View in Your Referrals
        </Link>
      </div>
    </div>
  </div>
);

const VendorReferralSuccessToast = () => (
  <div className={css.ReferralSuccessToast} data-cy="success-toast">
    <div className={css.ReferralSuccessContent}>
      <SuccessCheckIcon className={css.ReferralSuccessIcon} />
      <div className={css.ReferralSuccessContentText}>
        <p className={css.ReferralSuccessToastHeader}>
          Your referral has been submitted!
        </p>
      </div>
    </div>
  </div>
);

const ApplicationSuccessToast = () => (
  <div className={css.ReferralSuccessToast} data-cy="success-toast">
    <div className={css.ReferralSuccessContent}>
      <SuccessCheckIcon className={css.ReferralSuccessIcon} />
      <div className={css.ReferralSuccessContentText}>
        <p className={css.ReferralSuccessToastHeader}>
          We&apos;ve received your application!
        </p>
      </div>
    </div>
  </div>
);

interface ReferralFailureToastProps {
  error: string | undefined;
  email: string;
}

const ReferralFailureToast = ({ error, email }: ReferralFailureToastProps) => (
  <div className={css.ReferralFailureToast} data-cy="failure-toast">
    {error?.indexOf("This candidate has already been referred to this job") !==
    -1 ? (
      <>
        <img src={HmmImage} alt="" />
        <div>
          <h3
            className={css.ReferralFailureToastHeader}
            data-cy="failure-toast-header"
          >
            Hmmm... It looks like {email} has already been referred for this
            position.
          </h3>
          <p className={css.ReferralFailureToastBody}>
            <Link className={css.ReferralFailureToastLink} to={REFERRALS_PATH}>
              View your past referrals here
            </Link>{" "}
            or try referring a different candidate for this position.
          </p>
        </div>
      </>
    ) : (
      <>{error}</>
    )}
  </div>
);

const ReferCandidateForm = ({ jobId, reward }: ReferCandidateFormProps) => {
  const dispatch = useDispatch();
  const appSettings = useSelector(selectAppSettings);
  const referralLoader = useSelector(selectLoader(jobsActions.referCandidate));
  const sentByUser = useSelector(selectSentByUser);
  const user = useSelector((state: RootState) => {
    return selectUser(state);
  });
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const isTrustedVendor = user?.isTrustedVendor;
  const { pathname } = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState<FileData | null>(null);
  const [icsForm, setIcsForm] = useState<FileData | null>(null);
  const [isApplying, setIsApplying] = useState(false);
  const [trustedVendorNotes, setTrustedVendorNotes] = useState("");
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    linkedIn: "",
    phone: "",
    resume: "",
    icsForm: "",
  });

  const resetForm = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setLinkedIn("");
    setPhone("");
    setResume(null);
    setIcsForm(null);
    setIsApplying(false);
  }, []);

  useEffect(() => {
    // assuming success based on loader state
    const isSuccess =
      !referralLoader.loading &&
      referralLoader.loaded &&
      (!referralLoader.error || referralLoader.error.length === 0);

    // assuming error based on loader state
    const isFailure =
      !referralLoader.loading &&
      referralLoader.loaded &&
      referralLoader.error &&
      referralLoader.error.length > 0;

    if (isSuccess) {
      if (isApplying) {
        toast.success(ApplicationSuccessToast, {
          toastId: "referral-form-success-toast",
        });
      } else {
        if (appSettings.isVendorSite) {
          toast.success(VendorReferralSuccessToast, {
            toastId: "referral-form-success-toast",
          });
        } else {
          toast.success(ReferralSuccessToast, {
            toastId: "referral-form-success-toast",
          });
        }
      }
      resetForm();
      dispatch(jobsActions.referCandidateReset());
    } else if (isFailure) {
      toast.error(
        <ReferralFailureToast error={referralLoader.error} email={email} />,
        {
          toastId: "referral-form-error-toast",
        }
      );
      dispatch(jobsActions.referCandidateReset());
    }
  }, [dispatch, resetForm, referralLoader, email]);

  function onDrop(acceptedFiles: File[]) {
    const reader = new FileReader();
    const fileData = {
      name: "",
      file: "",
    };

    reader.onload = (e) => {
      const fileAsBinaryString = e?.target?.result?.toString() || "";
      const matches = fileAsBinaryString.match(/,(.*)$/);
      fileData.file = matches ? matches[1] : "";
      setResume(fileData);
    };

    acceptedFiles.forEach((file) => {
      reader.readAsDataURL(file);
      fileData.name = file.name;
      setResume(fileData);
    });
  }

  function onICSDrop(acceptedFiles: File[]) {
    const reader = new FileReader();
    const fileData = {
      name: "",
      file: "",
    };

    reader.onload = (e) => {
      const fileAsBinaryString = e?.target?.result?.toString() || "";
      const matches = fileAsBinaryString.match(/,(.*)$/);
      fileData.file = matches ? matches[1] : "";
      setIcsForm(fileData);
    };

    acceptedFiles.forEach((file) => {
      reader.readAsDataURL(file);
      fileData.name = file.name;
      setIcsForm(fileData);
    });
  }

  function handleUploadCancel() {
    setResume(null);
  }

  function handleICSUploadCancel() {
    setIcsForm(null);
  }

  const validateFirstName = (): string => {
    if (firstName.length === 0) {
      return "First name can't be blank.";
    }
    return "";
  };

  const validateLastName = (): string => {
    if (lastName.length === 0) {
      return "Last name can't be blank.";
    }
    return "";
  };

  const validatePhone = (): string => {
    if (phone.length === 0) {
      return "Phone can't be blank.";
    }
    if (phone.replace(/\D/gi, "").length < 10) {
      return "Phone must be valid.";
    }
    return "";
  };

  const validateLinkedIn = (): string => {
    if (linkedIn.trim().length === 0) {
      return "LinkedIn profile URL can't be blank.";
    }
    return "";
  };

  const validateResume = (): string => {
    if (!resume) {
      return "Please upload a resume.";
    }
    return "";
  };

  const validateICSForm = (): string => {
    if (!icsForm) {
      return 'Please upload Form I-797 or "Right to Represent"';
    }
    return "";
  };

  const validate = () => {
    const errors = {
      firstName: validateFirstName(),
      lastName: validateLastName(),
      email: validateEmail(email, false),
      phone: validatePhone(),
      linkedIn: "",
      resume: "",
      icsForm: "",
    };

    // If we're on the vendor site, also check for a valid resume
    if (appSettings.isVendorSite) {
      errors.resume = validateResume();
      errors.linkedIn = validateLinkedIn();

      if (!isTrustedVendor) {
        errors.icsForm = validateICSForm();
      }
    }

    setFormErrors(errors);

    // Make sure the length of all the errors equals 0.
    if (Object.values(errors).join("").length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    dispatch(jobsActions.referCandidateReset());
    toast.dismiss();
    const isValid = validate();

    if (isValid) {
      // If the user is applying and they were sent here by someone else,
      // make sure that person gets credit
      if (sentByUser && isApplying) {
        dispatch(
          jobsActions.referCandidate({
            jobId,
            publicUserId: sentByUser,
            firstName,
            lastName,
            email,
            phone,
            resume,
            ics_form: icsForm,
            isApplicant: isApplying,
            isFromVendorSite: appSettings.isVendorSite,
            linkedIn: linkedIn.trim(),
            trustedVendorNotes: trustedVendorNotes.trim(),
          })
        );

        track(events.APPLIED, {
          jobId,
          firstName,
          lastName,
          email,
          phone,
        });
      } else {
        dispatch(
          jobsActions.referCandidate({
            jobId,
            userId: user.id as number,
            firstName,
            lastName,
            email,
            phone,
            resume,
            ics_form: icsForm,
            isApplicant: isApplying,
            isFromVendorSite: appSettings.isVendorSite,
            linkedIn: linkedIn.trim(),
            trustedVendorNotes: trustedVendorNotes.trim(),
          })
        );

        track(events.SUBMITTED_REFERRAL, {
          jobId,
          firstName,
          lastName,
          email,
          phone,
        });
      }
    }
  };

  return (
    <>
      <ToastContainer
        toastClassName={css.ReferralToast}
        position="top-center"
        autoClose={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <form onSubmit={(e) => handleSubmit(e)} className={css.Form}>
        <div className={css.ApplyToggle}>
          <button
            className={!isApplying ? css.isActive : undefined}
            type="button"
            onClick={() => setIsApplying(false)}
          >
            {appSettings.isVendorSite
              ? "Submit a Candidate"
              : "Refer a Candidate"}
          </button>
          {appSettings.isVendorSite === false && (
            <button
              className={isApplying ? css.isActive : undefined}
              type="button"
              onClick={() => setIsApplying(true)}
            >
              Apply
            </button>
          )}
        </div>
        {isLoggedIn ? (
          <>
            <div className={cn(css.FormRow, css.FormRowNames)}>
              <Input
                label="First Name"
                name="firstName"
                className={css.FirstName}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={formErrors.firstName}
                autoCapitalize="on"
                autoComplete="given-name"
                autoCorrect="off"
              />
              <Input
                label="Last Name"
                name="lastName"
                className={css.LastName}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={formErrors.lastName}
                autoCapitalize="on"
                autoComplete="family-name"
                autoCorrect="off"
              />
            </div>
            <div className={css.FormRow}>
              <Input
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={formErrors.email}
                autoCapitalize="off"
                autoComplete="email"
                autoCorrect="off"
              />
            </div>
            <div className={css.FormRow}>
              <Input
                label="Phone"
                name="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                error={formErrors.phone}
                autoComplete="tel"
                autoCorrect="off"
              />
            </div>
            {appSettings.isVendorSite && (
              <>
                <div className={css.FormRow}>
                  <Input
                    label="LinkedIn Profile URL"
                    name="linkedIn"
                    type="text"
                    value={linkedIn}
                    onChange={(e) => setLinkedIn(e.target.value)}
                    error={formErrors.linkedIn}
                    autoCorrect="off"
                  />
                </div>
                {isTrustedVendor && (
                  <div className={css.FormRow}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Label htmlFor="trustedVendorNote" text="Notes" />
                      <textarea
                        className={css.TrustedVendorNote}
                        value={trustedVendorNotes}
                        onChange={(e) => setTrustedVendorNotes(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={css.FormRow}>
              <FileUpload
                name="resume"
                label="Resume"
                file={resume}
                onDrop={onDrop}
                handleUploadCancel={handleUploadCancel}
                hasError={formErrors.resume.length > 0}
              />
            </div>
            {appSettings.isVendorSite && (
              <div className={css.FormRow}>
                <FileUpload
                  name="I-797"
                  label="I-797 or Right to Represent"
                  file={icsForm}
                  onDrop={onICSDrop}
                  handleUploadCancel={handleICSUploadCancel}
                  hasError={formErrors.icsForm.length > 0}
                />
              </div>
            )}
            <div className={css.FormRow}>
              <Button
                className={css.SubmitReferralButton}
                id="SignUpWithEmailButton"
                isLoading={referralLoader.loading}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </>
        ) : (
          <div className={css.NotLoggedInMessage}>
            <div className={css.NotLoggedInBox}>
              <Text weight={600} color="black">
                You must be signed in to{" "}
                {isApplying ? "apply" : "submit a referral"}.
              </Text>
              <Button to={getLoginPath(pathname)}>Log In</Button>
            </div>
            <Text size="small" tag="span">
              Not a member? <Link to={SIGN_UP_PATH}>Sign up now</Link>
            </Text>
          </div>
        )}
      </form>
      {!appSettings.isVendorSite && (
        <div className={css.FormRow}>
          <FormSubmissionPolicy />
        </div>
      )}
    </>
  );
};

export default ReferCandidateForm;

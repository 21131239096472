import React, { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Link, Redirect, useHistory } from "react-router-dom";
import qs from "qs";

import { Heading, MarketingHero, MarketingLayout, Text } from "components";
import { JOBS_PATH, LOGIN_PATH } from "routes";
import { ReactComponent as MagnifyingGlass } from "images/magnifying-glass.svg";

import { REFRESH_TOKEN_KEY } from "reducks/auth";
import { selectAppSettings } from "reducks/meta";
import { useWindowSize } from "utilities/hooks";

import sb, { StoryblokService } from "utilities/storyblok";

import { ReactComponent as HeroArc } from "images/marketing/hero-arc-white.svg";
import HowItWorksTimelineItem from "components/HowItWorksTimelineItem";
import { QuoteProps } from "components/Quotes/Quote";
import Quotes from "components/Quotes/Quotes";
import bgImage from "images/marketing/hero-bg.png";
import team from "images/marketing/team.png";
import timeline1 from "images/marketing/timeline-1.jpg";
import timeline2 from "images/marketing/timeline-2.png";
import timeline3 from "images/marketing/timeline-3.png";

import css from "./Marketing.module.scss";

const HeroContent = () => {
  const [inputValue, setInputValue] = useState("");
  const history = useHistory();
  const { isBigMobile } = useWindowSize();
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const query = qs.stringify({
      query: inputValue,
    });
    history.push(`${JOBS_PATH}?${query}`);
  };

  return (
    <MarketingHero bgImage={bgImage} useWhiteArc>
      <div className={css.HeroContent}>
        <div className={css.heroLeft}>
          <Heading className={css.HeroHeading} variant="h1">
            Refer a friend, change a life.
          </Heading>
          <Text color="white" size={isBigMobile ? "large" : "medium"}>
            Agility Connect is a job board that helps you connect your network
            to jobs that they will love.
          </Text>
          <Text color="white" size={isBigMobile ? "large" : "medium"}>
            Become a member, refer a friend, and get rewarded when they land the
            job.
          </Text>
        </div>
        <form role="search" onSubmit={handleSubmit} className={css.SearchForm}>
          <input
            aria-label="Search for jobs"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={
              isBigMobile
                ? "Search by title, skill, or company..."
                : "Search for jobs"
            }
            className={css.SearchInput}
            autoComplete="off"
            autoSave="jobs-search"
            name="jobs-search"
            id="jobs-search"
            type="text"
            role="searchbox"
          />
          <button type="submit" className={css.SearchButton}>
            <MagnifyingGlass title="Search" />
          </button>
        </form>
        <Text color="white" size="medium" className={css.HeaderBottomText}>
          or{" "}
          <Link to={JOBS_PATH} className={css.HeaderTextLink}>
            Browse All Jobs
          </Link>
        </Text>
      </div>
    </MarketingHero>
  );
};

export const MarketingIndex = () => {
  const appSettings = useSelector(selectAppSettings);

  // If a refresh token exists, reroute to jobs page.
  // This is faster than checking if the user is actually logged
  // in and will keep the marketing page from being rendered while
  // the user is authenticated.
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (refreshToken) {
    return <Redirect to={JOBS_PATH} />;
  }

  if (appSettings.isVendorSite) {
    return <Redirect to={LOGIN_PATH} />;
  }

  const howItWorks = [
    {
      description: "How Connect Works",
      alignment: "center",
    },
    {
      description: "You refer a talented IT friend in your network.",
      image: timeline1,
    },
    {
      description:
        "Our dedicated team helps them get a job at one of our hundreds of hiring partners, from startups to Fortune 100 and 50 companies.",
      alignment: "right",
      image: timeline2,
    },
    {
      description: "You earn a referral reward once they're hired.",
      image: timeline3,
    },
    {
      description: "It's simple and quick",
      alignment: "center",
    },
  ];

  const [quotes, setQuotes] = useState<Array<QuoteProps>>([]);

  useEffect(() => {
    const getStories = async () => {
      const stories = await sb.get("home");
      const testimonials = StoryblokService.getStory(
        "Testimonials",
        stories
      ) as Array<QuoteProps>;

      setQuotes(testimonials);
    };

    getStories();
  }, []);

  return (
    <MarketingLayout hero={<HeroContent />}>
      <>
        <section className={css.stats}>
          <div className={css.statsContent}>
            <div className={css.stat}>
              <span className={css.statNumber}>10,000+</span>
              <span className={css.statText}>members</span>
            </div>
            <div className={css.stat}>
              <span className={css.statNumber}>$2,100</span>
              <span className={css.statText}>average referral reward</span>
            </div>
            <div className={css.stat}>
              <span className={css.statNumber}>100+</span>
              <span className={css.statText}>hiring partners</span>
            </div>
            <div className={css.stat}>
              <span className={css.statNumber}>1,000+</span>
              <span className={css.statText}>people placed</span>
            </div>
          </div>
          <HeroArc className={css.statsArc} />
        </section>
        <section className={css.MainContent}>
          {howItWorks.map((item, index) => (
            <HowItWorksTimelineItem key={item.description} {...item} />
          ))}
        </section>
        <section className={css.realHumans}>
          <div className={css.realHumansContent}>
            <img src={team} alt="Real humans" className={css.realHumansImage} />
            <div className={css.realHumansText}>
              <h2 className={css.realHumansHeading}>Powered by Real Humans</h2>
              <Text color="white" size="large">
                Connect was built by us, Agility Partners, an IT recruiting
                company who believes in doing things differently.
              </Text>
              <Text color="white" size="large">
                We created Connect to break through the noise in the recruiting
                industry and spend more time on the humans behind the resumes.
              </Text>
            </div>
          </div>
        </section>
        <Quotes quotes={quotes} />
      </>
    </MarketingLayout>
  );
};

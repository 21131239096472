/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import {
  BlurredText,
  Button,
  Divider,
  Heading,
  JobDetails,
  Nav,
  Panel,
  ReferCandidateForm,
  Text,
} from "components";
import { JOBS_PATH, SIGN_UP_PATH, getLoginPath } from "routes";
import { jobsActions, selectJob } from "reducks/jobs";
import { selectIsUserLoggedIn, selectUser, userActions } from "reducks/user";

import { selectAppSettings, selectJobTypeNameById } from "reducks/meta";
import { ReactComponent as DollarSignSvg } from "images/dollar-sign.svg";
import { ReactComponent as LockSvg } from "images/lock.svg";
import { getSalaryRange } from "utilities/getSalaryRange";
import { numberWithCommas } from "utilities/formatting";
import { scrollToTop } from "utilities/scroll";
import { selectFilterUrl } from "reducks/jobsFilters";
import { selectLoader } from "reducks/loaders";

import { events, track } from "utilities/analytics";
import { LoadingJobDetail } from "./loading";
import { ShareJobModal } from "./share-job-modal";
import css from "./JobsDetailPage.module.scss";

export const JobsDetailPage = () => {
  const { id: paramId, sharedUserId } = useParams<{
    id: string;
    sharedUserId?: string;
  }>();
  const id = paramId === "refer_all" ? "0" : paramId;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleShareModalDismiss = () => setIsShareModalOpen(false);
  const loader = useSelector(selectLoader(`job-${id}`));
  const filterParams = useSelector(selectFilterUrl);
  const { pathname } = useLocation();
  const appSettings = useSelector(selectAppSettings);

  useEffect(() => {
    scrollToTop();
    dispatch(jobsActions.fetchJob({ jobId: id, includeAuth: isLoggedIn }));
  }, [dispatch, id, isLoggedIn]);

  // If the user has come to this page through someone's share link,
  // save the sharer's info in localstorage so we can tie them to this user
  // if they apply to a job.
  useEffect(() => {
    if (sharedUserId) {
      dispatch(userActions.setSentByUser(sharedUserId));
    }
  }, [dispatch, sharedUserId]);

  const job = useSelector(selectJob(id));
  const jobTypeName = useSelector(selectJobTypeNameById(job.job_type));
  const isLoading = job.id === -1 && !loader.loaded;

  // Analytics
  useEffect(() => {
    if (job.id !== -1) {
      track(events.VISITED_JOB, {
        jobId: job.id,
        name: job.name,
        company: job.company?.name,
        salary_high: job.salary_high,
        salary_low: job.salary_low,
      });
    }
  }, [job]);

  return (
    <div>
      <Helmet>
        {job.name && <title>{job.name} - Agility Connect</title>}
        <meta property="og:title" content={`${job.name} - Agility Connect`} />
        <meta
          property="description"
          content={`Refer a friend and earn $${numberWithCommas(job.reward)}!`}
        />
        <meta
          property="og:description"
          content={`Refer a friend and earn $${numberWithCommas(job.reward)}!`}
        />

        {job.status === 10 && (
          <script type="application/ld+json">
            {JSON.stringify(
              {
                "@context": "schema.org",
                "@type": "JobPosting",
                title: job.name,
                datePosted: job.posted_date,
                description: job.description.replace(/<\/?[^>]+(>|$)/g, ""),
                applicantLocationRequirements: {
                  "@type": "Country",
                  name: "USA",
                },
                employmentType: jobTypeName,
                hiringOrganization: {
                  "@type": "Organization",
                  name: "Agility Partners",
                  sameAs: "https://agilityconnect.io",
                  logo: "http://agilityconnect.io/logomark.png",
                },
                jobLocation: {
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                    addressLocality: job.company.city,
                    addressRegion: job.company.state,
                    addressCountry: "US",
                  },
                },
              },
              null,
              2
            )}
          </script>
        )}
      </Helmet>
      <Nav />
      <div className={css.Content}>
        <Button
          className={css.BackButtonLink}
          to={{
            pathname: JOBS_PATH,
            search: filterParams ? `?${filterParams}` : undefined,
          }}
          variant="text-link"
          tabIndex={-1}
          hasBackArrow
        >
          Back to all Jobs
        </Button>
        <ShareJobModal
          isOpen={isShareModalOpen}
          handleDismiss={handleShareModalDismiss}
          job={job}
          user={user}
        />
        <Panel className={css.JobDetailsPanel}>
          {isLoading ? (
            <LoadingJobDetail />
          ) : (
            <>
              <Heading variant="h1" align="left" className={css.PageHeading}>
                {job.name}
              </Heading>
              <JobDetails company={job.company} />

              {appSettings.isVendorSite === false && (
                <>
                  <Text tag="span" className={css.BasePayHeading}>
                    Base pay
                  </Text>
                  {isLoggedIn ? (
                    <Text tag="span" weight={600}>
                      {getSalaryRange(job)}
                    </Text>
                  ) : (
                    <BlurredText>$12,345 - $678,910</BlurredText>
                  )}
                </>
              )}

              {appSettings.isVendorSite && (
                <>
                  {user.isTrustedVendor ? (
                    <div className={css.HorizontalStats}>
                      {job.job_type === 2 && job.salary_high ? (
                        <div>
                          <Text tag="span" className={css.BasePayHeading}>
                            Max salary
                          </Text>
                          <Text tag="span" weight={600}>
                            ${numberWithCommas(job.salary_high)}
                          </Text>
                        </div>
                      ) : (
                        <div>
                          <Text tag="span" className={css.BasePayHeading}>
                            Max Bill rate
                          </Text>
                          <Text tag="span" weight={600}>
                            ${job.bill_rate}/hr
                          </Text>
                        </div>
                      )}
                      {job.work_order_number && (
                        <div className={css.HorizontalStat}>
                          <Text tag="span" className={css.BasePayHeading}>
                            Work Order #
                          </Text>
                          <Text tag="span" weight={600}>
                            {job.work_order_number}
                          </Text>
                        </div>
                      )}
                      {job.bh_ae_first_name && (
                        <div className={css.HorizontalStat}>
                          <Text tag="span" className={css.BasePayHeading}>
                            Job Owner
                          </Text>
                          <Text tag="span" weight={600}>
                            {job.bh_ae_first_name} {job.bh_ae_last_name}
                          </Text>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {job.c2c_rate && (
                        <>
                          <Text tag="span" className={css.BasePayHeading}>
                            Max C2C rate
                          </Text>
                          <Text tag="span" weight={600}>
                            ${job.c2c_rate}/hr
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {!isLoggedIn && (
                <div className={css.LogInBanner}>
                  <LockSvg className={css.Lock} />
                  <Text>
                    <Link to={getLoginPath(pathname)}>Log in</Link> or{" "}
                    <Link to={SIGN_UP_PATH}>sign up</Link> to view salary and
                    company information
                  </Text>
                </div>
              )}

              <div className={css.Tags}>
                <span
                  className={css.Tag}
                  data-title="Position Type"
                  data-cy="PositionType"
                >
                  {job.position_type.name}
                </span>
                <span className={css.Tag} data-cy="JobType">
                  {jobTypeName}
                </span>
                <span className={css.Tag} data-cy="Openings">
                  {`${job.num_positions_open} ${
                    job.num_positions_open === 1 ? "Opening" : "Openings"
                  }`}
                </span>
                {user.isTrustedVendor && job.bh_priority && (
                  <span className={css.Tag} data-title="Priority">
                    Priority: {job.bh_priority}
                  </span>
                )}
              </div>

              {appSettings.isVendorSite === false && (
                <div className={css.ShareBanner}>
                  <div className={css.ShareBannerText}>
                    <Text
                      color="green"
                      tag="span"
                      weight={700}
                      className={css.ShareBannerNumber}
                    >
                      ${numberWithCommas(job.reward)}
                    </Text>
                    <Text color="green" tag="span" weight={600}>
                      reward per hire
                    </Text>
                  </div>

                  <Button
                    variant="green"
                    className={css.ShareButton}
                    onClick={() => setIsShareModalOpen(true)}
                    data-cy="share-btn-top"
                  >
                    <DollarSignSvg className={css.DollarSign} /> Share to Earn
                  </Button>
                </div>
              )}

              <Heading variant="h2" align="left" className={css.SectionHeading}>
                About this Role
              </Heading>
              <div
                className={css.TextSection}
                dangerouslySetInnerHTML={{ __html: job.description }}
              />
              <>
                <Heading
                  variant="h2"
                  align="left"
                  className={css.SectionHeading}
                >
                  Benefits and Perks
                </Heading>
                <div
                  className={css.TextSection}
                  dangerouslySetInnerHTML={{ __html: job.benefits }}
                />
                <Heading
                  variant="h2"
                  align="left"
                  className={css.SectionHeading}
                >
                  The Ideal Candidate
                </Heading>
                <div
                  className={css.TextSection}
                  dangerouslySetInnerHTML={{ __html: job.qualifications }}
                />
              </>
              {appSettings.isVendorSite === false && (
                <>
                  <div className={css.ShareDetails}>
                    <p className={css.ShareDetailsGreen}>
                      Share this job. Make ${numberWithCommas(job.reward)}.
                    </p>
                    <p className={css.ShareDetailsBlack}>
                      When a friend applies to this position and gets hired,
                      you’ll get credited with a referral reward!*
                    </p>
                  </div>
                  <Button
                    className={css.DetailShareButton}
                    variant="green"
                    onClick={() => setIsShareModalOpen(true)}
                    data-cy="share-btn-bottom"
                  >
                    <DollarSignSvg className={css.DollarSign} /> Share This Job
                  </Button>
                  <Divider />
                  <Text className={css.ReferralDisclaimerTwo} size="small">
                    *Reward paid upon hire of your candidate according to our
                    Recruiting Agreement Policy (see right).
                  </Text>
                </>
              )}
            </>
          )}
        </Panel>
        <Panel className={css.ReferPanel}>
          <ReferCandidateForm
            jobId={job.id}
            reward={numberWithCommas(job.reward)}
          />
        </Panel>
      </div>
    </div>
  );
};
/* eslint-enable react/no-danger */

import React, { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";

import { sagaMiddleware, store } from "reducks/store";
import AgilityRouter from "routes";
import sagas from "reducks/sagas";

import styles from "./App.module.scss";

sagaMiddleware.run(sagas);

function FallbackComponent() {
  return <div>An error has occurred!</div>;
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <Provider store={store}>
        <div className={styles.App}>
          <AgilityRouter />
        </div>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);

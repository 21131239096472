import {
  StrictEffect,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import qs from "qs";

import { REFERRALS_CACHE_KEY, cacheActions } from "reducks/cache";
import { doAPIRequest, responseSuccess } from "reducks/sagas";
import { Action } from "types/redux";
import { selectAppSettings } from "reducks/meta";

import {
  FetchJobsPayload,
  FileData,
  Job,
  JobBase,
  ReferCandidatePayload,
  jobsActions,
} from "./ducks";

interface JobsResponse {
  count: number;
  next: string;
  previous: string;
  results: Array<JobBase>;
}

function* fetchJobs(action: Action<FetchJobsPayload>): any {
  const { payload } = action;
  const appSettings = yield select(selectAppSettings);

  /**
   * join numeric filters with correct facet category
   */
  const parseNumArray = (
    a: number[] | undefined,
    joinString: string
  ): string | undefined =>
    a && a.length > 0
      ? a.map((item) => `${joinString}:${item}`).join(",")
      : undefined;

  /**
   * replace the comma with double underscore for each city and state
   */
  const parseCities = (cities: string[]): string | undefined =>
    cities.length > 0
      ? cities
          .map((city) => `city_and_state:${city.replace(", ", "__")}`)
          .join(",")
      : undefined;

  /**
   * parse the filters and create the facets
   */
  const parseFacets = (p: FetchJobsPayload): string | undefined => {
    // if nothing exists then do not create facets
    if (
      (!p.job_type || p.job_type.length === 0) &&
      (!p.position_type || p.position_type.length === 0) &&
      (!p.industry || p.industry.length === 0) &&
      p.city.length === 0
    ) {
      return undefined;
    }

    const job_types = parseNumArray(p.job_type, "job_type");
    const position_types = parseNumArray(p.position_type, "position_type_id");
    const industries = parseNumArray(p.industry, "industry_id");
    const cities = parseCities(p.city);

    return `${job_types ? `${job_types},` : ""}${
      position_types ? `${position_types},` : ""
    }${industries ? `${industries},` : ""}${cities || ""}`.replace(/,$/, "");
  };

  const queryObject: {
    limit?: number;
    offset?: number;
    facets?: string;
    q?: string;
    vs?: boolean;
  } = {
    limit: payload.limit,
    offset: payload.offset,
    facets: parseFacets(payload),
    q: payload.query || undefined,
  };

  if (appSettings.isVendorSite) {
    queryObject.vs = true;
  }

  const queryString = qs.stringify(queryObject);

  const options = {
    method: "GET",
    path: `/jobs/?${queryString}`,
    includeAuth: payload.includeAuth,
    useCache: true,
  };

  const { body, status }: { body: JobsResponse; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    return yield put(jobsActions.fetchJobsFailure("Error fetching jobs"));
  }

  if (payload.append) {
    yield put(jobsActions.fetchMoreJobsSuccess({ ...body }));
  } else {
    yield put(jobsActions.fetchJobsSuccess({ ...body }));
  }
}

function* fetchJob(
  action: Action<{ jobId: string; includeAuth: boolean }>
): Generator<
  StrictEffect,
  { body: Job; status: number } | void,
  { body: Job; status: number }
> {
  const { jobId, includeAuth } = action.payload;

  const options = {
    method: "GET",
    path: `/jobs/${jobId}/`,
    includeAuth,
    useCache: true,
    ttl: 90,
  };

  const { body, status }: { body: Job; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    return yield put(
      jobsActions.fetchJobFailure({
        id: jobId,
        message: `Error fetching job ${jobId}`,
      })
    );
  }

  yield put(jobsActions.fetchJobSuccess(body));
}

interface ReferCandidateRequestBody {
  job: number;
  referred_by?: number;
  referred_by_public?: string;
  candidate: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    resume: FileData | null | undefined;
    ics_form: FileData | null | undefined;
    is_applicant: boolean;
    is_from_vendor_site?: boolean;
    linked_in_profile?: string;
    trusted_vendor_notes?: string;
  };
}

function* referCandidate(action: Action<ReferCandidatePayload>): any {
  const { payload } = action;
  let path = "/referrals/";

  const requestBody: ReferCandidateRequestBody = {
    job: payload.jobId,
    candidate: {
      first_name: payload.firstName,
      last_name: payload.lastName,
      email: payload.email as string,
      phone: payload.phone as string,
      resume: payload.resume,
      ics_form: payload.ics_form,
      is_applicant: payload.isApplicant,
      is_from_vendor_site: payload.isFromVendorSite || false,
      linked_in_profile: payload.linkedIn,
      trusted_vendor_notes: payload.trustedVendorNotes,
    },
  };

  // If the user applied from a shared job link, we're going to count them
  // as a referral.
  if (payload.publicUserId) {
    path = "/referrals/public/";
    requestBody.referred_by_public = payload.publicUserId;
    requestBody.candidate.is_applicant = false;
  } else {
    requestBody.referred_by = payload.userId;
  }

  const options = {
    method: "POST",
    body: requestBody,
    path,
    includeAuth: true,
  };

  const { body, status } = yield call(doAPIRequest, options);

  if (!responseSuccess(status)) {
    return yield put(
      jobsActions.referCandidateFailure(body.candidate.join(" "))
    );
  }

  yield put(jobsActions.referCandidateSuccess());
  // clear referrals cache
  yield put(cacheActions.clearItem(REFERRALS_CACHE_KEY));
}

function* watchFetchJobs() {
  yield takeLatest(`${jobsActions.fetchJobs}`, fetchJobs);
}

function* watchFetchMoreJobs() {
  yield takeLatest(`${jobsActions.fetchMoreJobs}`, fetchJobs);
}

function* watchFetchJob() {
  yield takeLatest(`${jobsActions.fetchJob}`, fetchJob);
}

function* watchReferCandidate() {
  yield takeLatest(`${jobsActions.referCandidate}`, referCandidate);
}

export const jobsSagas = [
  watchFetchJobs(),
  watchFetchMoreJobs(),
  watchFetchJob(),
  watchReferCandidate(),
];
